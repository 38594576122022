<template>
    <div style="text-align: left;background: #FFFFFF;">
        <el-card>
            <div @click="$oucy.back()" class="m-b-20 pointer">&lt;返回</div>
            <h2 class="text-center">余额充值</h2>
            <el-form ref="form" :model="form" label-width="80px" :rules="rules" class="m-t-40">
                <el-form-item label="充值方式" v-if="0">
                    <span class="btn m-r-10" :class="{'select':form.payMode==0}" @click="form.payMode=0,focus()">
                        <img src="@/assets/images/stockCar/WeChat.png" class="btnImg">
                        微信支付
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                    <span class="btn" :class="{'select':form.payMode==1}" @click="form.payMode=1,focus()">
                        <img src="@/assets/images/stockCar/Alipay.png" class="btnImg">
                        支付宝
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                </el-form-item>
                <el-form-item label="充值金额" prop="fee">
                    <el-input v-model="form.fee" type="number"></el-input>
                   <div class="c-2 m-t-10">
                    *最低充值金额1元
                   </div>
                </el-form-item>
                <el-form-item label="" v-if="0">
                    <el-checkbox v-model="form.desc">同意《乐搜家余额提现条款》</el-checkbox>
                </el-form-item>
                <el-form-item v-show="hasQrcodeShow">
                    <div ref="myLoginQrCodeUrl">
                        
                    </div>
                    <div class="c-2">请扫码支付</div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">立即充值</el-button>
                    <!-- <el-button @click="$oucy.back()">返回</el-button> -->
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";

import { localSet, localGet, localDel } from "@/store/store"
import { balance } from "@/service"
import QRCode from 'qrcodejs2'
export default {
    name: "EditUser",
    components: {},
    data() {
        var validatePass = (rule, value, callback) => {
          if (value <1) {
            callback(new Error('充值金额必须大于1元'));
          } else {            
            callback();
          }
        };
        return {
            form: {
                type:'createRechargeOrder',
                payPlatform:'browser',
                payMode: 0,
                fee: null,
            },
            rules: {
                fee: [
                    { required: true, message: '请输入充值金额', trigger: 'blur' },
                    // { type: 'number', message: '充值金额必须为数字值'},
                    { validator: validatePass, trigger: 'blur' }
                ],
            },
            qrCode:null,
            hasQrcodeShow:false,
        }
    },
    mounted() {
        this.qrCode = new QRCode(this.$refs.myLoginQrCodeUrl, {
            // text: text,
            // text: me.$oucy.baseHost+'/routerView/wechartLogin/'+me.$oucy.scanKeyOfPreLogin+'?p='+me.$oucy.getUUID(),
            width: 146,
            height: 146,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
        })
        this.eventBus.$off(this.$oucy.msgKeys.BALANCE_PAY_SUCCESS).$on(this.$oucy.msgKeys.BALANCE_PAY_SUCCESS,(res)=>{
            console.log('支付事件：');
            console.log(res);
            this.$alert('充值成功')
            this.$oucy.go('/acc/balancePaySuccess')

        })
    },
    methods: {
        createRechargeOrder() {
            this.form.uuid= this.$oucy.getUUID()
            localSet('payObject',this.form)
            oucy.replace('/payCenter/pay?type=createRechargeOrder')
            return
            balance.createRechargeOrder(this.form).then(res => {
                this.creatQrCode(res.qrCode)
            })
        },
        focus(){
            this.onSubmit()
        },
        onSubmit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.createRechargeOrder()
                }
            })
            console.log('submit!');
        },
        creatQrCode(text) {
            console.log(this.qrCode)
            this.hasQrcodeShow=true
            this.qrCode && this.qrCode.clear();
            this.qrCode.makeCode(text)
        },
    }
}
</script>
<style scoped lang="less">
.btn {
    line-height: 1.2;
    display: inline-block;
    padding: 6px 36px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}
</style>